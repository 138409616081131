import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { CenterLayout } from '../../atoms/center-layout/center-layout.atom';
import { Link } from 'react-router-dom';
import { URL_PAGES } from '../../../app-logic/constants/url.const';
import { useAuth } from 'react-oidc-context';

export const LogoutPage = () => {
  const auth = useAuth();
  const endSessionEndpoint = process.env.REACT_APP_END_SESSION_ENDPOINT  
  const endSessionEndpointWithoutReturnTo = endSessionEndpoint ? endSessionEndpoint.split("returnTo")[0] : '';
  
  useEffect(() => {
    if (auth && auth.isAuthenticated) {
      auth.signoutRedirect();
    }
  }, [auth]);

  return (    
    <CenterLayout>
      <Typography>
        You have successfully been logged out. Click <Link to={endSessionEndpointWithoutReturnTo + 'returnTo=' + location.origin + URL_PAGES.HOME.url}>here</Link> to log back in.
      </Typography>
    </CenterLayout>
  );
};
