import React, { createContext, ReactElement, useContext, useState } from 'react';
import { DataHolder, ResponseError } from '@adatree/react-api-sdk';
import { Alert, Snackbar, lighten, darken } from '@mui/material';
import { ErrorMessage } from '../../components/atoms/error-message/error-message.atom';
import { COLOUR } from '../constants/colour.const';

interface ContextProps {
  addMessage: (message: string, error?: Error | ResponseError, dataHolders?: DataHolder[]) => void;
}

const MessengerContext = createContext<ContextProps | undefined>(undefined);

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

const useMessenger = () => {
  const context = useContext(MessengerContext);
  if (!context) {
    throw new Error('useMessenger must be used within a MessengerContext');
  }
  return context;
};

const MessengerProvider = ({ children }: ProviderProps) => {
  const [message, setMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<Error | ResponseError>();
  const [dataHolders, setDataHolders] = useState<DataHolder[] | undefined>();

  const addMessage = (message: string, error?: Error | ResponseError, dataHolders?: DataHolder[]) => {
    setMessage(message);
    setError(error);
    setDataHolders(dataHolders);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <MessengerContext.Provider value={{ addMessage }}>
      {children}

      <Snackbar
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={error === undefined ? 6000 : undefined}
        // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ '&.MuiSnackbar-root': { left: '300px', bottom: '18px' } }}
      >
        <div>
          {message && !error && (
            <Alert
              severity="success"
              onClose={handleClose}
              sx={{
                background: lighten(COLOUR.SUCCESS, 0.8),
                border: `2px solid ${darken(COLOUR.SUCCESS, 0.3)}`,
                color: darken(COLOUR.SUCCESS, 0.8),
              }}
            >
              <>{message}</>
            </Alert>
          )}
          {error && (
            <ErrorMessage userFriendlyMessage={message} error={error} dataHolders={dataHolders} onClose={handleClose} />
          )}
        </div>
      </Snackbar>
    </MessengerContext.Provider>
  );
};

export { MessengerProvider, useMessenger };
