export type UrlItem = {
  label: string;
  url: string;
};

export const URL_BUCKETS = {
  CHARTS: '/charts',
  ADMIN: '/admin',
  CONSENTS: '/consents',
  DASHBOARD: '/dashboard',
  DATA_HOLDERS: '/data-holders',
  DOCS: '/docs',
  EXCHANGE: '/exchange',
  EXPLORE_DATA: '/explore',
  GRANTEES: '/grantees',
  USE_CASES: '/use-cases',
};

export const URL_PARAMAS = {
  cdrArrangementId: 'cdraid',
  cdrRequestType: 'crt',
  consentId: 'cid',
  status: 'cst',
  consumerId: 'conid',
  consumerEmail: 'cone',
  dataHolderBrandId: 'dhid',
  dataHolderSessionId: 'dhsid',
  tabId: 'tab',
  resourceOwnerId: 'roid',
  useCaseId: 'ucid',
  oldestCreated: 'ocd',
  newestCreated: 'ncd'
};

export const URL_PAGES = {
  // Admin
  CONSENT_DASHBOARD_CONFIG: { label: 'Dashboard settings', url: `${URL_BUCKETS.ADMIN}/consent-dashboard-config` },
  SOFTWARE_PRODUCTS: { label: 'Software product', url: `${URL_BUCKETS.ADMIN}/software-products` },
  DYNAMIC_FORMS: { label: 'Dynamic form schemas', url: `${URL_BUCKETS.ADMIN}/dynamic-forms-page` },
  TENANT_SETUP: { label: 'Tenant setup', url: `${URL_BUCKETS.ADMIN}/tenant-setup` },

  // Charts
  CHARTS: { label: 'Consent flow', url: `${URL_BUCKETS.CHARTS}/activity` },
  CHARTS_2: { label: 'Usage', url: `${URL_BUCKETS.CHARTS}/usage` },
  VISUAL_DATA_EXPLORER: { label: 'Visual data explorer', url: `${URL_BUCKETS.CHARTS}/visual-data-explorer` },

  // Consents
  CONSENT: { label: 'Consents', url: `${URL_BUCKETS.CONSENTS}` },
  CONSENT_CREATE: { label: 'Create a consent', url: `${URL_BUCKETS.CONSENTS}/create` },
  CONSENT_DETAILS: { label: 'Consent details', url: `${URL_BUCKETS.CONSENTS}/:${URL_PARAMAS.consentId}` },
  CONSENT_CALLBACK: { label: 'Consent callback', url: `${URL_BUCKETS.CONSENTS}/callback-popup` },

  // Consents
  DASHBOARD: { label: 'Consent Dashboard', url: `${URL_BUCKETS.DASHBOARD}` },
  DASHBOARD_AUTH: { label: 'Authentication', url: `${URL_BUCKETS.DASHBOARD}/authentication` },
  DASHBOARD_ENV: { label: 'Environment', url: `${URL_BUCKETS.DASHBOARD}/environment` },
  DASHBOARD_THEME: { label: 'Theme', url: `${URL_BUCKETS.DASHBOARD}/theme` },

  // Use cases
  GRANTEE_LIST: { label: 'View grantees', url: `${URL_BUCKETS.GRANTEES}` },

  // Use cases
  USE_CASE_CREATE: { label: 'Create use case', url: `${URL_BUCKETS.USE_CASES}/create` },
  USE_CASE_EDIT: { label: 'Edit use case', url: `${URL_BUCKETS.USE_CASES}/edit/:useCaseId` },
  USE_CASE_LIST: { label: 'View use cases', url: `${URL_BUCKETS.USE_CASES}` },

  // Data holders
  DH_STATUS: { label: 'Status & Outages', url: `${URL_BUCKETS.DATA_HOLDERS}/data-holder-status` },
  DOC_DATA_HOLDER_TESTING: { label: 'Compliance', url: `${URL_BUCKETS.DATA_HOLDERS}/data-holder-testing` },
  DATA_COLLECTIONS: { label: 'Collections', url: `${URL_BUCKETS.DATA_HOLDERS}/data-collections` },
  DATA_HOLDER_SESSIONS: { label: 'Sessions', url: `${URL_BUCKETS.DATA_HOLDERS}/sessions` },
  DATA_HOLDER_INTERACTIONS: { label: 'Interactions', url: `${URL_BUCKETS.DATA_HOLDERS}/interactions` },
  PROD_EXPLORER: { label: 'Product Explorer', url: `${URL_BUCKETS.DATA_HOLDERS}/product-reference-data` },
  VALIDATE_ARRANGEMENT: { label: 'Validate Arrangement', url: `${URL_BUCKETS.DATA_HOLDERS}/validate-arrangement` },

  // Docs
  DOCS: { label: 'Overview', url: `${URL_BUCKETS.DOCS}` },
  DEV_RESOURCES: { label: 'Dev resources', url: `${URL_BUCKETS.DOCS}/dev-resources` },
  API_DOC_CDR_STATEMENT: { label: 'CDR Statement API docs', url: `${URL_BUCKETS.DOCS}/cdr-statement-api-doc` },
  API_DOC_EXCH: { label: 'Exchange API docs', url: `${URL_BUCKETS.DOCS}/exchange-api-doc` },
  API_DOC_INSIGHTS: { label: 'Insights API docs', url: `${URL_BUCKETS.DOCS}/insights-api-doc` },
  API_TEST: { label: 'API tests', url: `${URL_BUCKETS.DOCS}/api-test` },
  DOC_SANDBOX_DATA_HOLDERS: {
    label: 'Sandbox Model Data Holders',
    url: `${URL_BUCKETS.DOCS}/sandbox-model-data-holders`,
  },
  API_DOC_CUSTOM_SV_INSIGHTS: {
    label: 'Custom Insights API docs',
    url: `${URL_BUCKETS.DOCS}/custom-insights-api-doc`,
  },

  // Exchange
  EXCH_HOME: { label: 'Services', url: `${URL_BUCKETS.EXCHANGE}` },
  MOGO_HOME: { label: 'MogoPlus', url: `${URL_BUCKETS.EXCHANGE}/mogoplus` },
  MOGO_INSIGHT_RPT_SEARCH: { label: 'Search', url: `${URL_BUCKETS.EXCHANGE}/mogoplus/insights-report` },
  MOGO_INSIGHT_RPT_CREATE: {
    label: 'Create',
    url: `${URL_BUCKETS.EXCHANGE}/mogoplus/insights-report/create`,
  },
  MOGO_INSIGHT_RPT_SEND_EMAIL: {
    label: 'Email',
    url: `${URL_BUCKETS.EXCHANGE}/mogoplus/insights-report/send-email`,
  },

  // Explore data
  EXPLORE_HOME: { label: 'Explore data', url: `${URL_BUCKETS.EXPLORE_DATA}` },
  EXPLORE_BANKING: { label: 'Banking data', url: `${URL_BUCKETS.EXPLORE_DATA}/banking-data` },
  EXPLORE_BANKING_REAL_TIME: {
    label: 'Real time Banking data',
    url: `${URL_BUCKETS.EXPLORE_DATA}/banking-real-time-data`,
  },
  EXPLORE_ENERGY: { label: 'Energy data', url: `${URL_BUCKETS.EXPLORE_DATA}/energy-data` },

  // NON buckets
  AUTH_CALLBACK: { label: 'Authentication callback', url: '/authentication' },
  ERROR: { label: 'Error', url: '/error' },
  HOME: { label: 'Home', url: '/' },
  LOGIN: { label: 'Login', url: '/login' },
  LOGOUT: { label: 'Logout', url: '/logout' },
  MAINTENANCE: { label: 'Maintenance', url: '/maintenance' },
  ONBOARDING: { label: 'Onboarding', url: '/onboarding' },
  PROFILE: { label: 'Profile', url: '/profile' },
  TEST_LAB: { label: 'Test Lab', url: '/test-lab' },
};
