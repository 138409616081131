import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { Logger } from '@adatree/atomic-components';
import { DataHolder } from '@adatree/react-api-sdk';
import { Alert, lighten, darken, Box, Typography } from '@mui/material';
import { COLOUR } from '../../../app-logic/constants/colour.const';
import { ResponseError } from '../../../generated/mgmt/data';
import { parseDataHolderError } from '../../../app-logic/utils/data-holder/data-holders.util';

interface Props {
  userFriendlyMessage: string;
  error: Error | ResponseError;
  dataHolders?: DataHolder[];
  onClose?: () => void;
}

type ServerError = {
  title?: string;
  detail?: string;
};
export const ErrorMessage = ({ error, userFriendlyMessage, dataHolders, onClose }: Props) => {
  const [serverErrors, setServerErrors] = useState<ServerError[]>([]);
  const [response, setResponse] = useState<Response>();

  Bugsnag.notify(error);
  Logger.error(`Name: ${error.name}.`, error);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.name === 'ResponseError' || error.response) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setResponse(error.response);
    }

    const parseErrors = async (error: ResponseError) => {
      if (!error.response.bodyUsed) {
        try {
          const body = await error.response.json();
          if (body.errors && Array.isArray(body.errors)) {
            setServerErrors(body.errors);
          } else if (body.error) {
            setServerErrors([{ detail: body.error }]);
          }
        } catch (josnError) {
          Logger.error('Error parsing Response JSON', error.response);
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.name === 'ResponseError' || error.response) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseErrors(error);
    }
  }, []);

  return (
    <Alert
      severity="error"
      variant="filled"
      sx={{
        background: lighten(COLOUR.DANGER, 0.8),
        border: `2px solid ${darken(COLOUR.DANGER, 0.2)}`,
        color: darken(COLOUR.DANGER, 0.6),
        mr: 10,
      }}
      onClose={onClose}
    >
      {!response && <>{`${userFriendlyMessage} - ${error.message}`}</>}

      {response && (
        <details style={{ cursor: 'pointer' }}>
          <summary>{userFriendlyMessage} - Click for more details</summary>
          <Box sx={{ p: 0.3, pt: 1.6 }}>
            {error && (
              <>
                {serverErrors && serverErrors.length > 0 && (
                  <>
                    <Box sx={{ pb: 1, mb: 2, borderBottom: `1px solid ${COLOUR.DANGER}` }}>
                      <Typography sx={{ mt: 1, mb: 0.6 }}>Error details:</Typography>
                      {serverErrors.map((error, index: number) => {
                        return (
                          <Box key={index}>
                            {error.title && <Typography sx={{ ml: 1 }}>{`${error.title}`}</Typography>}
                            {error.detail && !dataHolders && (
                              <Typography sx={{ ml: 1 }}>{`${error.detail}`}</Typography>
                            )}
                            {error.detail && dataHolders && (
                              <Typography sx={{ ml: 1 }}>{`${parseDataHolderError(
                                error.detail,
                                dataHolders,
                              )}`}</Typography>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                    <Typography sx={{ mt: 1, mb: 0.6 }}>More details:</Typography>
                  </>
                )}

                <Typography sx={{ ml: 1 }}>{`Name: ${error.name}`}</Typography>
                <Typography sx={{ ml: 1 }}>{`Message: ${error.message}`}</Typography>
                <Typography sx={{ ml: 1 }}>{`Status code: ${response.status}`}</Typography>
                {response.statusText && <Typography sx={{ ml: 1 }}>{`Status text: ${response.statusText}`}</Typography>}
                {response.url && <Typography sx={{ ml: 1 }}>{`URL: ${response.url}`}</Typography>}
              </>
            )}
          </Box>
        </details>
      )}
    </Alert>
  );
};
